// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  EnvironmentPlaceName: 'dev',
  production: false,
  URL: 'https://laservicecontract-dev.volvo.com/',
  clientId: 'bba070d4-85d8-4039-9094-96ce1fe4cfe2',
  authority: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
  redirectUri: 'https://laservicecontract-dev.volvo.com',
  postLogoutRedirectUri: 'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
  OcpToken: '',
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  appInsights: {
    instrumentationKey: '72a7f7a7-c5ab-4faa-b67f-3e132d5cdecb',
  },

  //API Paths
  UrlApiContract: 'https://laservicecontract-api-dev.volvo.com/v1/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
